import React from 'react';
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
import useMobile from '@utils/useMobile';
import { Heading, TextBlock, Col, Button, Grid } from '@ui';
import { Tabs, BankLinks } from '@components';
import { ReactComponent as SecurePaymentIcon } from '@assets/icons/secure-payment.svg';
import { ReactComponent as InfoIcon } from '@assets/icons/icon-i.svg';
import Edit from './Edit';
import AddContacts from './AddContacts';
import CardPayment from './CardPayment';

const options = [
  { value: '1', label: 'Chocolate' },
  { value: '2', label: 'Strawberry' },
  { value: '3', label: 'Vanilla' },
  { value: '4', label: 'Chocolate' },
  { value: '5', label: 'Strawberry' },
  { value: '6', label: 'Vanilla' },
  { value: '7', label: 'Chocolate' },
  { value: '8', label: 'Strawberry' },
  { value: '9', label: 'Vanilla' },
];

const Step3 = () => {
  const history = useHistory();
  const { slug } = useParams();

  const { isMobile } = useMobile();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(`/declare/3`);
  };
  return (
    <form className='shadow-2 m:shadow-none bg-white m:bg-transparent p-8 m:px-0 m:pt-8 m:pb-0' onSubmit={handleSubmit}>
      <Col className='flex justify-between w-full mb-3'>
        <Heading text='Mokėjimo būdas' size='s' component='h2' />
        <TextBlock
          className='inline-flex align-center whitespace-nowrap'
          style={isMobile ? {} : { fontSize: '1.3rem' }}>
          <SecurePaymentIcon width={isMobile ? '1.2rem' : '1.5rem'} className='mr-2 m:mr-3' />
          <p>Saugus apmokėjimas</p>
        </TextBlock>
      </Col>
      <Tabs
        className='mb-10 m:mb-12'
        values={[
          {
            title: {
              text: 'Elektroninė bankininkystė',
            },
            content: {
              children: <BankLinks />,
            },
          },
          {
            title: {
              text: (
                <>
                  Banko kortelė
                  <InfoIcon width='1.925rem' className='ml-2 m:ml-3' />
                </>
              ),
            },

            content: {
              children: <CardPayment />,
            },
          },
        ]}
      />
      <Heading text='Gavėjo duomenys' className='mb-3 m:mb-4' size='s' component='h2' />
      <Tabs
        values={[
          {
            title: {
              text: 'Fizinis asmuo',
            },
            content: {
              children: slug === 'edit' ? <Edit /> : <AddContacts />,
            },
          },
          {
            title: {
              text: 'Juridinis asmuo',
            },

            content: {
              children: (
                <Grid hasGutter>
                  <Col size='50%'>
                    <Select
                      classNamePrefix='Select'
                      className='Form__select'
                      placeholder='Įmonės pavadinimas*'
                      options={options}
                    />
                  </Col>
                </Grid>
              ),
            },
          },
        ]}
      />
      <Col className='flex align-center mt-6 m:mt-10 m:flex-col'>
        <Button style={{ minWidth: '17.5rem' }} text='Apmokėti' type='submit' />
        <TextBlock className='ml-5 opacity-50 font-light m:mt-10'>
          <p>Šie duomenys bus naudojami muitinės sąskaitos išrašymui.</p>
        </TextBlock>
      </Col>
    </form>
  );
};

export default Step3;
