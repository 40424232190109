import React from 'react';
import ListItem from './ListItem';

const HowTo = () => {
  const listItems = [
    {
      image: { src: 'media/how-to-step-1.svg', alt: 'alt text' },
      title: 'Randate savo siuntą.',
    },
    {
      image: { src: 'media/how-to-step-2.svg', alt: 'alt text' },
      title: 'Sistema suformuoja importo deklaraciją.',
    },
    {
      image: { src: 'media/how-to-step-3.svg', alt: 'alt text' },
      title: 'Užsakote paslaugą. <strong>Čia jūsų darbas baigtas!</strong>',
    },
    {
      image: { src: 'media/how-to-step-4.svg', alt: 'alt text' },
      title: 'Sistema deklaruoja siuntą.',
    },
    {
      image: { src: 'media/how-to-step-5.svg', alt: 'alt text' },
      title: 'Kurjeris užbaigia pristatymą Jums.',
    },
  ];

  return (
    <ul className='HowTo'>
      {listItems.map((item, index) => (
        <ListItem key={index} number={index} {...item} />
      ))}
    </ul>
  );
};

export default HowTo;
