import React from 'react';
import { TextBlock, Col, Grid, Link } from '@ui';
import { ReactComponent as EditIcon } from '@assets/icons/edit.svg';

const Edit = () => {
  return (
    <Grid hasGutter>
      <Col size='50%'>
        <TextBlock className='line-h-2'>
          <p>
            <strong>
              Vardenis Pavardenis
              <br />
              Gatvės pav. 14
              <br />
              Vilnius
              <br />
              Lietuva
              <br />
              +370 666 12345
            </strong>
          </p>
        </TextBlock>
      </Col>
      <Col size='50%'>
        <div className='flex justify-end m:justify-start m:mt-4'>
          <Link
            text={
              <>
                <EditIcon width='1.575rem' className='mr-2' /> Keisti duomenis
              </>
            }
          />
        </div>
      </Col>
    </Grid>
  );
};

export default Edit;
