import React, { useState } from 'react';
import { Section, Col, Container, Heading, Button, Main, TextArea } from '@ui';
import { Message, Modal } from '@components';
import TextBlock from '@ui/TextBlock';

const Modals = () => {
  const [modal1Active, setModal1Active] = useState(false);
  const [modal2Active, setModal2Active] = useState(false);
  return (
    <Main className='bg-sand-light'>
      <Section>
        <Container className='pt-13 pb-20'>
          <Heading text='Modals' className='mb-10' size='s' component='h1' />
          <Col>
            <Button text='04_neatitinka duomenys' onClick={() => setModal1Active(true)} />
            <Modal isOpen={modal1Active} onRequestClose={() => setModal1Active(false)} contentLabel='Example Modal'>
              <Heading text='Neatitinka duomenys?' className='mb-4 m:mb-6' size='s' component='h2' />
              <Message color='violet' className='mb-4 m:mb-10'>
                <p>
                  Jeigu deklaruok24.lt sistemoje matomi duomenys nesutampa su jūsų turimais siuntos duomenimis, prašome
                  pateikti patikslinančiąją informaciją ir mes su jumis susisieksime.
                </p>
              </Message>
              <form>
                <TextArea className='mb-5 m:mb-10' label={{ text: 'Jūsų komentarai' }} textarea={{ name: 'message' }} />
                <Col className='flex align-center m:flex-col'>
                  <Button text='Susiję dokumentai' variant='secondary' />
                  <TextBlock className='font-light ml-4 opacity-50 m:text-center m:mt-4'>
                    <p>(Iki 3 dokumentų, viso iki 5 MB)</p>
                  </TextBlock>
                </Col>
                <Col className='flex m:flex-col align-center mt-7 m:mt-10'>
                  <Button text='Siųsti' />
                  <Button className='ml-3 m:ml-0 m:mt-4' text='atšaukti' color='red' />
                </Col>
              </form>
            </Modal>
          </Col>

          <Col className='mt-7'>
            <Button text='05_neatitinka duomenys_info' onClick={() => setModal2Active(true)} />
            <Modal isOpen={modal2Active} onRequestClose={() => setModal2Active(false)} contentLabel='Example Modal'>
              <Heading text='Gavėjo duomenys' className='mb-4 m:mb-6' size='s' component='h2' />
              <Message color='violet' className='mb-6 m:mb-8'>
                <p>Ačiū, informacija pateikta! deklaruok24.lt patikrins duomenis ir su jumis susieks.</p>
              </Message>
              <Button onClick={() => setModal2Active(false)} text='Uždaryti' />
            </Modal>
          </Col>
        </Container>
      </Section>
    </Main>
  );
};

export default Modals;
