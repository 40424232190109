import React from 'react';
import PropTypes from 'prop-types';
import cx from '@utils/classNames';

const Grid = ({ children, hasGutter, className, ...rest }) => {
  return (
    <div className={cx('Grid', hasGutter && 'Grid--has-gutter', className)} {...rest}>
      {children}
    </div>
  );
};

Grid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hasGutter: PropTypes.bool,
};

export default Grid;
