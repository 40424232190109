import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from '@utils/classNames';

const Main = ({ children, className, ...rest }) => {
  const [style, setStyle] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/') {
      setStyle('home');
    } else {
      setStyle(pathname.substr(1));
    }
  }, [pathname]);

  return (
    <main className={cx('Main', style && `Main--${style}`, className)} {...rest}>
      {children}
    </main>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Main;
