import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import cx from '@utils/classNames';
import { TextBlock } from '@ui';

const FaqItem = ({ question, answer, active: state }) => {
  const [active, setActive] = useState(state);
  return (
    <div className='Faq__item bg-white'>
      <button onClick={() => setActive(!active)} className='Faq__question'>
        <h3 className={cx(active ? 'text-black' : 'text-grey-dark')}>{question}</h3>
        <span className={cx('Faq__icon', `${active && 'isActive'}`)} />
      </button>
      <Collapse isOpened={active}>
        <div className='Faq__content'>
          <TextBlock className='font-light'>{answer}</TextBlock>
        </div>
      </Collapse>
    </div>
  );
};

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.node.isRequired,
  status: PropTypes.bool,
};

FaqItem.defaultProps = {
  status: false,
};

export default FaqItem;
