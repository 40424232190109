import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from '@utils/classNames';
import { Link } from '@ui';

const Tabs = ({ values, className }) => {
  const activeIndex = values.findIndex((item) => item.active);
  const [activeTab, setActiveTab] = useState(activeIndex > 0 ? activeIndex : 0);

  return (
    <div className={cx('Tabs', className)}>
      <div className='Tabs__head'>
        <div className='Tabs__overflow-container'>
          <ul>
            {values.map((tab, index) => (
              <li key={index} className={cx(activeTab === index && 'isActive')}>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(index);
                  }}
                  color='grey-dark'
                  className='font-medium'
                  {...tab?.title}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='Tabs__content pt-5 p:mt-8'>
        {values.map((tab, index) => activeTab === index && <div key={index}>{tab.content.children}</div>)}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      }),
      content: PropTypes.shape({ children: PropTypes.node }),
    })
  ),
};
export default Tabs;
