import React from 'react';
import { Section, Grid, Col, Container, Heading, TextBlock, Image, Main } from '@ui';
import { AboutUsCards } from '@components';

const About = () => {
  return (
    <Main className='bg-sand-light m:bg-white'>
      <Section className='Section--about-us'>
        <Container className='pt-13 m:pt-19'>
          <Grid>
            <Col size='50%' className='bg-sand-light m:bg-white pr-16 m:pr-0 relative z-2'>
              <div className='mb-5 m:mb-12 m:-mx-6 m:py-6 m:px-6 m:bg-sand-light'>
                <Heading text='Apie mus' className='hidden m:block' size='m' component='h1' />
                <Heading text='Apie mus' className='m:hidden' size='s' component='h1' />
              </div>
              <TextBlock className='mb-5'>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </p>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                  laborum Duis aute irure dolor in reprehenderit in volup. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id est laborum Duis aute irure dolor in
                  reprehenderit in volup.
                </p>
              </TextBlock>
            </Col>
            <Col size='50%' className='relative'>
              <Image src='/media/about-us@2x.png' alt='alt text' />
            </Col>
          </Grid>
          <AboutUsCards />
        </Container>
      </Section>
    </Main>
  );
};

export default About;
