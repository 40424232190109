import React from 'react';
import { Heading, Grid, TextBlock, Button } from '@ui';
import useMobile from '@utils/useMobile';
import { ReactComponent as Step1Icon } from '@assets/icons/step-1-success.svg';

const Step4 = () => {
  const { isMobile } = useMobile();
  return (
    <>
      <Grid className='shadow-2 m:shadow-none bg-white m:bg-transparent p-8 m:px-0 m:pt-8 m:pb-0'>
        <Heading
          text={
            <span className='inline-flex align-center'>
              <Step1Icon width={isMobile ? '3.1rem' : '4.2rem'} className='mr-3 m:mr-5' />
              Sėkmė!
            </span>
          }
          className='mb-3 m:mb-3'
          size='s'
          component='h2'
        />
        <TextBlock>
          <p>
            Importo deklaracijos užsakymas pateiktas.
            <br />
            Muitinei patvirtinus deklaraciją, kurjeris užbaigs siuntos pristatymą.
          </p>
        </TextBlock>
      </Grid>
      <Grid className='shadow-2 m:shadow-none bg-white m:bg-transparent p-8 mt-4 m:p-0'>
        <Heading text='Turite daugiau siuntų, kurias reikia deklaruoti?' className='mb-4' size='s' component='h2' />
        <Button to='/declare' text='Pirmyn' />
      </Grid>
    </>
  );
};

export default Step4;
