import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import useMobile from '@utils/useMobile';
import { Link } from '@ui';
import { ReactComponent as CheckIcon } from '../assets/icons/check-icon.svg';

const SideNav = () => {
  const { path } = useRouteMatch();
  const { isMobile } = useMobile();

  return (
    <ul className='SideNav'>
      <li>
        <Link
          to={`${path}/1`}
          text={
            <>
              <span>1.</span>Siunta
            </>
          }
          color='grey-dark'
          active
        />
      </li>
      <li>
        <Link
          to={`${path}/2`}
          text={
            <>
              <span>2.</span>Mokesčiai
            </>
          }
          color='grey-dark'
        />
      </li>
      <li>
        <Link
          to={`${path}/3`}
          text={
            <>
              <span>3.</span>Apmokėjimas
            </>
          }
          color='grey-dark'
        />
      </li>
      {!isMobile && (
        <li>
          <Link
            to={`${path}/success`}
            text={
              <>
                <span>
                  <CheckIcon width='1.7rem' style={{ fill: 'currentcolor' }} />
                </span>
                Pateikta
              </>
            }
            color='grey-dark'
          />
        </li>
      )}
    </ul>
  );
};

export default SideNav;
