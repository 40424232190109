import React from 'react';
import { useHistory } from 'react-router-dom';
import useMobile from '@utils/useMobile';
import { Heading, TextBlock, Col, Checkbox, Link, Button } from '@ui';
import { Message } from '@components';

const Step1 = () => {
  const history = useHistory();
  const { isMobile } = useMobile();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(`/declare/2`);
  };
  return (
    <form className='shadow-2 m:shadow-none bg-white m:bg-transparent p-8 m:px-0 m:pt-8 m:pb-0' onSubmit={handleSubmit}>
      <Heading text='Siuntos informacija' className='mb-3 m:mb-5' size='s' component='h2' />
      <TextBlock className='mb-8 m:mb-10 font-light'>
        <ul>
          <li>
            <p>
              <strong>Siuntos nr.:</strong> 67ASFA5VEWV5
            </p>
          </li>
          <li>
            <p>
              <strong>Kurjeris:</strong> ITELLA/DPD/OMNIVA
            </p>
          </li>
          <li>
            <p>
              <strong>Pirkimo sąskaitos nr.:</strong> AAABBBCCC12
            </p>
          </li>
          <li>
            <p>
              <strong>Pirkimo data:</strong> 2020-11-11
            </p>
          </li>
        </ul>
      </TextBlock>
      <Heading text='Prekės' className='mb-2 m:mb-2' size='s' component='h2' />
      <div className='Table'>
        <div className='Table__overflow'>
          <div className='Table__container'>
            <table>
              <thead>
                <tr>
                  <td>
                    <h3>Description</h3>
                  </td>
                  <td>
                    <h3>Qty</h3>
                  </td>
                  <td>
                    <h3>Sales price, €</h3>
                  </td>
                  <td>
                    <h3>Discount, €</h3>
                  </td>
                  <td>
                    <h3>VAT rate</h3>
                  </td>
                  <td>
                    <h3>Total, €</h3>
                  </td>
                  <td>
                    <h3>Composition</h3>
                  </td>
                  <td>
                    <h3>Country of origin</h3>
                  </td>
                  <td>
                    <h3>HS Code</h3>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='pb-2'>
                    <p>1234567 FALKE Active Breeze Sneaker Socks 12345678Black</p>
                  </td>
                  <td>
                    <p>1</p>
                  </td>
                  <td>
                    <p>9,68</p>
                  </td>
                  <td>
                    <p>0,00</p>
                  </td>
                  <td>
                    <p>0%</p>
                  </td>
                  <td>
                    <p>9,68</p>
                  </td>
                  <td>
                    <p>Elastane 2% Polyamide 30% Lyocell 68%</p>
                  </td>
                  <td>
                    <p>Germany</p>
                  </td>
                  <td>
                    <p>612003464</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Total</p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p>9,68</p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p>0%</p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isMobile && (
        <>
          <Heading text='Dokumentai' className='m:mt-11 m:mb-5' size='s' component='h2' />
          <Message color='violet' className='m:mb-9'>
            <p>Siuntėjas nepateikė žemiau išvardintų dokumentų. Prašome juos pridėti.</p>
          </Message>
          <Button text='Pridėti pirkimo sąskaitą faktūrą' variant='secondary' className='m:mb-4' />
          <Button text=' Pridėti pavedimo kopiją' variant='secondary' className='m:mb-4' />
          <Col className='text-center'>
            <Link text='+ Pridėti dar vieną' color='grey-dark' className='opacity-50' style={{ fontSize: '1.5rem' }} />
          </Col>
        </>
      )}
      <Col className='mt-6 m:mt-10'>
        <Checkbox label={{ text: 'Esu šios siuntos gavėjas' }} />
      </Col>
      <Col className='mt-2'>
        <Checkbox
          label={{
            text: (
              <>
                Duomenys teisingi (<Link text='Neatitinka?' />)
              </>
            ),
          }}
        />
      </Col>
      <Col className='mt-6 m:mt-11'>
        <Button text='Toliau: mokesčiai' type='submit' />
      </Col>
    </form>
  );
};

export default Step1;
