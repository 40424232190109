import React from 'react';
import { Col, Grid, Input } from '@ui';

const AddContacts = () => {
  return (
    <Grid hasGutter>
      <Col size='50%'>
        <Input className='mb-2' label={{ text: 'Vardas ir pavardė*' }} input={{ name: 'name_surname', type: 'text' }} />
        <Input className='mb-2' label={{ text: 'Telefonas*' }} input={{ name: 'phone', type: 'text' }} />
        <Input label={{ text: 'El. paštas*' }} input={{ name: 'email', type: 'email' }} />
      </Col>
    </Grid>
  );
};

export default AddContacts;
