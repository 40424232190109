import React from 'react';
import { Section, Container, Heading, TextBlock, Main } from '@ui';

const TextPage = () => {
  return (
    <Main className='bg-sand-light m:bg-white'>
      <Section className='Section--text-page'>
        <Container className='pt-13 m:pt-19 pb-20 m:pb-18'>
          <div className='mb-5 m:mb-12 m:-mx-6 m:py-6 m:px-6 m:bg-sand-light'>
            <Heading text='Lorem ipsum dolor' className='m:hidden' size='s' component='h1' />
            <Heading text='Lorem ipsum dolor' className='hidden m:block' size='m' component='h1' />
          </div>
          <TextBlock className='mb-9 m:mb-12'>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum Duis aute irure dolor in reprehenderit in volup. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum Duis aute irure
              dolor in reprehenderit in volup.
            </p>
          </TextBlock>
          <Heading text='Lorem ipsum dolor' className='mb-3' size='xs' component='h2' />
          <TextBlock className='mb-9 m:mb-12'>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur.
            </p>
            <p>
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
              laborum Duis aute irure dolor in reprehenderit in volup. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum Duis aute irure dolor in reprehenderit in
              volup.
            </p>
          </TextBlock>
          <Heading text='Lorem ipsum dolor' className='mb-3' size='xs' component='h2' />
          <TextBlock className='mb-9 m:mb-12'>
            <p>
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
              laborum Duis aute irure dolor in reprehenderit in volup. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum Duis aute irure dolor in reprehenderit in
              volup.
            </p>
          </TextBlock>
          <Heading text='Lorem ipsum dolor' className='mb-3' size='xxs' component='h3' />
          <TextBlock className='mb-4 m:mb-12'>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur.
            </p>
          </TextBlock>
          <Heading text='Lorem ipsum dolor' className='mb-6 m:mb-6' size='xxs' component='h3' />
          <TextBlock className='mb-8 m:mb-0'>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur.
            </p>
          </TextBlock>
        </Container>
      </Section>
    </Main>
  );
};

export default TextPage;
