import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Header, Footer, CookieConsent, DeveloperNav } from './components';
import Home from './pages/Home';
import DeclareGoods from './pages/DeclareGoods';
import About from './pages/About';
import Price from './pages/Price';
import Contacts from './pages/Contacts';
import TextPage from './pages/TextPage';
import Modals from './pages/Modals';

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/declare' component={DeclareGoods} />
        <Route path='/about' component={About} />
        <Route path='/price' component={Price} />
        <Route path='/contacts' component={Contacts} />
        <Route path='/privacy-policy' component={TextPage} />
        <Route path='/modals' component={Modals} />
      </Switch>
      <CookieConsent />
      <Footer />
      <DeveloperNav />
    </BrowserRouter>
  );
};

export default App;
