import React from 'react';
import { Section, Container, Heading, Main } from '@ui';
import { ContactForm } from '@components';

const Contacts = () => {
  return (
    <Main className='bg-sand-light m:bg-white'>
      <Section>
        <Container className='pt-13 m:pt-19 pb-20 m:pb-15'>
          <div className='mb-5 m:mb-12 m:-mx-6 m:py-6 m:px-6 m:bg-sand-light'>
            <Heading text='Kontaktai' className='m:hidden' size='s' component='h1' />
            <Heading text='Kontaktai' className='hidden m:block' size='m' component='h1' />
          </div>
          <ContactForm />
        </Container>
      </Section>
    </Main>
  );
};

export default Contacts;
