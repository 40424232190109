import React from 'react';
import { Section, Grid, Col, Container, Heading, TextBlock, Main, Image } from '@ui';
import { Faq, HowTo, Search } from '@components';
import { ReactComponent as InfoIcon } from '@assets/icons/icon-i.svg';

const Home = () => {
  return (
    <Main>
      <Section className='Section--home-hero'>
        <Container
          className='bg-sand-light pt-15 pb-16 m:pb-0'
          style={{ backgroundImage: 'url("media/home-hero-bg.svg")' }}>
          <Grid className='m:pt-7'>
            <Col size='50%' className='ml-auto'>
              <Heading
                text='Patogus importo siuntų deklaravimas muitinėje'
                className='mb-5 m:mb-5'
                size='l'
                component='h1'
              />
              <TextBlock className='mb-5 m:mb-5'>
                <p>
                  Visos siuntos iš ne Europos Sąjungos šalių turi būti deklaruotos muitinėje. DEKLARUOK24.LT už gavėją
                  atlieka visus muitinės formalumus.
                </p>
              </TextBlock>
              <Search
                className='mr-auto'
                label={{
                  text: <span className='pr-1'>Siuntos numeris</span>,
                }}
              />
            </Col>
            <Col className='hidden m:flex w-full'>
              <Image className='m:mt-9' src='media/home-hero-bg.svg' alt='hero image' />
            </Col>
          </Grid>
        </Container>
      </Section>
      <Section className='py-16 m:pt-14 m:pb-17'>
        <Container>
          <Heading text='Kaip tai veikia?' className='mb-6' />
          <HowTo />
        </Container>
      </Section>
      <Section className='Section--home-cta'>
        <Container className='py-13 m:py-16 bg-violet' style={{ backgroundImage: 'url("media/hero-cta-bg.svg")' }}>
          <Grid>
            <Col size='50%'>
              <Heading
                text='Deklaruokite ir apmokėkite siuntos mokesčius'
                color='white'
                className='mb-8 m:mb-10 font-medium'
              />
              <TextBlock color='white' size='l'>
                <p>Raskite savo siuntą pagal siuntos numerį</p>
              </TextBlock>
            </Col>
            <Col size='50%'>
              <Search
                className='mt-10 m:mt-6 ml-auto'
                label={{
                  text: (
                    <>
                      <span className='pr-1'>Siuntos numeris</span>
                      <InfoIcon className='m:hidden' width='1.9rem' height='1.9rem' />
                    </>
                  ),
                }}
                tooltip={<InfoIcon className='hidden m:block' width='1.9rem' height='1.9rem' />}
              />
            </Col>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container className='pt-15 pb-20 m:py-14'>
          <Grid className='mb-4'>
            <Col size='25%' className='pr-5 m:pr-0 m:mb-10'>
              <Heading text='D.U.K. Pagalba' />
            </Col>
            <Col size='75%'>
              <Faq />
            </Col>
          </Grid>
        </Container>
      </Section>
    </Main>
  );
};

export default Home;
