import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactLink } from 'react-router-dom';
import cx from '@utils/classNames';

const Link = ({ to, href, text, hasUnderline, color, className, active, ...rest }) => {
  const resolvedStyle = cx(
    'Link',
    hasUnderline && 'Link--has-underline',
    `text-${color}`,
    active && 'isActive',
    className && className
  );

  if (to)
    return (
      <ReactLink to={to} className={resolvedStyle} {...rest}>
        {text}
      </ReactLink>
    );

  return React.createElement(href ? 'a' : 'button', { className: resolvedStyle, href, ...rest }, text);
};

Link.propTypes = {
  color: PropTypes.oneOf(['green', 'black', 'white', 'grey-dark']),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  active: PropTypes.bool,
  hasUnderline: PropTypes.bool,
};

Link.defaultProps = {
  color: 'green',
  active: false,
};

export default Link;
