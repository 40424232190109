import React from 'react';
import { Grid, Col, TextBlock } from '@ui';
import { ReactComponent as Step1Icon } from '@assets/about-us/about-step-1.svg';
import { ReactComponent as Step2Icon } from '@assets/about-us/about-step-2.svg';
import { ReactComponent as Step3Icon } from '@assets/about-us/about-step-3.svg';

const AboutUsCards = () => {
  return (
    <Grid className='AboutUsCards'>
      <Col className='AboutUsCards__item bg-white shadow-2'>
        <Step1Icon />
        <TextBlock>
          <p>
            Profesionalūs
            <br />
            muitinės tarpininkai
          </p>
        </TextBlock>
      </Col>
      <Col className='AboutUsCards__item bg-white shadow-2'>
        <Step2Icon />
        <TextBlock>
          <p>
            IT infrastruktūra:
            <br />
            integracijos su vežėjų bei muitinės sistemomis
          </p>
        </TextBlock>
      </Col>
      <Col className='AboutUsCards__item bg-white shadow-2'>
        <Step3Icon />
        <TextBlock>
          <p>
            Unikali Lietuviška siuntų
            <br />
            deklaravimo sistema
          </p>
        </TextBlock>
      </Col>
    </Grid>
  );
};

export default AboutUsCards;
