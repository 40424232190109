import React, { useState } from 'react';
import { TextBlock, Button, Link } from '@ui';

const CookieConsent = () => {
  const [showCookie, setshowCookie] = useState(true);

  return (
    showCookie && (
      <div className='CookieConsent bg-violet'>
        <div className='CookieConsent__content'>
          <TextBlock color='white'>
            <p>
              Šioje svetainėje naudojami slapukai, kurių padedami galėsite joje naršyti patogiau.{' '}
              <Link to='/' text='Sužinoti daugiau' color='white' hasUnderline />
            </p>
          </TextBlock>
          <Button text='Sutinku' className='mt-4' onClick={() => setshowCookie(false)} />
        </div>
      </div>
    )
  );
};

export default CookieConsent;
