import React, { useState, useEffect } from 'react';
import { Container, Grid, Link } from '@ui';
import { useLocation } from 'react-router-dom';
import useMobile from '@utils/useMobile';
import cx from '@utils/classNames';
import { ReactComponent as Logo } from '@assets/logo.svg';
import { ReactComponent as PhoneIcon } from '@assets/icons/phone-icon.svg';
import { ReactComponent as UserIcon } from '@assets/icons/user-icon.svg';
import { ReactComponent as ArrowDown } from '@assets/icons/arrow-down.svg';
import { ReactComponent as MenuIcon } from '@assets/icons/menu.svg';

const Header = () => {
  const [showNav, setShowNav] = useState(false);
  const { isMobile } = useMobile();
  const { pathname } = useLocation();

  useEffect(() => {
    setShowNav(false);
  }, [pathname]);

  return (
    <header className='Header bg-white'>
      <Container noGutter className='Header__container'>
        <Grid className='Header__grid'>
          <Link to='/' text={<Logo className='Header__logo' />} />
          <button onClick={() => setShowNav(!showNav)} className='Header__burger'>
            <MenuIcon fill={showNav ? '#fff' : '#231F20'} />
          </button>
          <nav className={cx('Header__nav', showNav && 'isActive')}>
            <ul className='Header__nav-primary'>
              <li>
                <Link to='/' color='black' active text='Kaip tai veikia?' />
              </li>
              <li>
                <Link to='/price' color='black' text='Kaina' />
              </li>
              <li>
                <Link to='/' color='black' text='D.U.K.' />
              </li>
              <li>
                <Link to='/about' color='black' text='Apie mus' />
              </li>
              <li>
                <Link to='/contacts' color='black' text='Kontaktai' />
              </li>
            </ul>
            <ul className='Header__nav-secondary'>
              <li>
                <Link
                  href='tel:+37061212345'
                  color='black'
                  text={
                    <>
                      <PhoneIcon className='text-violet' width='1.8rem' height='2.3rem' />
                      <span className='pl-2 font-semi-bold'>+370 612 12345</span>
                    </>
                  }
                />
              </li>
              <li>
                <Link
                  to='/'
                  color='black'
                  text={
                    <>
                      <UserIcon className='text-violet' width='1.8rem' height='2.3rem' />
                      <span className='pl-2'>Prisijungti</span>
                    </>
                  }
                />
              </li>
              {isMobile ? (
                <li className='m:mt-10'>
                  <Link to='/' color='white' text='LT' />
                  <Link to='/' className='m:ml-3' color='white' text='EN' />
                </li>
              ) : (
                <li>
                  <div className='Header__lang'>
                    <div className='Header__lang-tile'>
                      <Link color='black' text='LT' />
                      <ArrowDown width='1.1rem' />
                    </div>
                    <div className='Header__lang-content'>
                      <ul>
                        <li>
                          <Link to='/' color='black' text='EN' />
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </nav>
        </Grid>
      </Container>
    </header>
  );
};

export default Header;
