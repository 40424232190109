import React from 'react';

import { Container, TextBlock, Grid, Col, Link } from '@ui';

const Footer = () => {
  return (
    <footer className='Footer py-4 m:pb-5 m:pt-13'>
      <Container>
        <Grid>
          <Col size='50%'>
            <TextBlock className='font-light'>
              <p>© 2021 UAB "Trevio". Visos teisės saugomos.</p>
            </TextBlock>
          </Col>
          <Col size='50%' className='m:mb-16'>
            <nav className='Footer__nav'>
              <ul>
                <li>
                  <Link to='/privacy-policy' color='black' text='Privatumo politika' />
                </li>
                <li>
                  <Link to='/' color='black' text='Naudojimosi sąlygos' />
                </li>
                <li>
                  <Link to='/about' color='black' text='Apie mus' />
                </li>
                <li>
                  <Link to='/contacts' color='black' text='Kontaktai' />
                </li>
              </ul>
            </nav>
          </Col>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
