import { useLayoutEffect, useState } from 'react';

export default function useMobile() {
  const [mobile, setMobile] = useState(window.innerWidth < 768);

  useLayoutEffect(() => {
    function updateScreen() {
      setMobile(window.innerWidth < 768);
    }
    window.addEventListener('resize', updateScreen);

    return () => window.removeEventListener('resize', updateScreen);
  }, []);
  return { isMobile: mobile };
}
