import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from '@utils/classNames';

const Input = ({ label, size, className, input, tooltip, error }) => {
  const [active, setActive] = useState(false);
  const id = Date.now();

  const handleBlur = (e) => {
    if (!e.currentTarget.value) setActive(false);
  };

  return (
    <div
      className={cx(
        'Form__control',
        tooltip && 'Form__control--has-tooltip',
        active && 'isActive',
        error && 'hasError',
        className
      )}>
      <input
        id={id}
        onFocus={() => setActive(true)}
        onBlur={handleBlur}
        className={cx('Form__input', `Form__input--${size}`, 'text-black bg-white')}
        {...input}
      />
      {label && (
        <label id={id} className='Form__label text-grey-dark'>
          {label?.text}
        </label>
      )}

      {tooltip && <div className={cx('Form__tooltip', `Form__tooltip--${size}`)}>{tooltip}</div>}
    </div>
  );
};

Input.propTypes = {
  size: PropTypes.oneOf(['l', 'm']),
  input: PropTypes.shape({}),
  label: PropTypes.shape({
    text: PropTypes.node,
  }),
  className: PropTypes.string,
  tooltip: PropTypes.node,
  error: PropTypes.bool,
};

Input.defaultProps = {
  size: 'm',
};

export default Input;
