import React from 'react';
import PropTypes from 'prop-types';
import cx from '@utils/classNames';

const Col = ({ children, size, className, ...rest }) => {
  return (
    <div className={cx('Grid_col', size && `Grid__col--${size}`, className)} {...rest}>
      {children}
    </div>
  );
};

Col.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['25%', '50%', '75%']),
};

export default Col;
