import React from 'react';
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import cx from '@utils/classNames';
import useMobile from '@utils/useMobile';
import { Section, Grid, Col, Container, Heading, Main } from '@ui';
import { SideNav } from '@components';
import Pin from './Pin';
import PinError from './PinError';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3/index';
import Step4 from './Step4';

const DeclareGoods = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { isMobile } = useMobile();

  const excludeNav = ['/declare', '/declare/error', '/declare/success'];

  return (
    <Main className='bg-sand-light m:bg-white'>
      <Section>
        <Container className='pt-13 pb-20 m:pt-19 m:pb-0'>
          <Grid className='mb-3 m:pb-10'>
            <Col
              size='25%'
              className={cx(
                'pr-5 m:px-6 m:py-7 m:bg-sand-light m:-mx-6 m:w-auto',
                excludeNav.includes(pathname) && 'm:hidden'
              )}>
              <div>
                {isMobile ? (
                  <Heading text='Siuntos deklaravimas' className='m:mb-4' size='m' component='h1' />
                ) : (
                  <Heading
                    text='Siuntos deklaravimas ir mokesčių apmokėjimas'
                    className='mb-6'
                    size='s'
                    component='h1'
                  />
                )}

                <SideNav />
              </div>
            </Col>
            <Col size='75%'>
              <Switch>
                <Route path={path} component={Pin} exact />
                <Route path={`${path}/1`} component={Step1} />
                <Route path={`${path}/2`} component={Step2} />
                <Route path={`${path}/3`} component={Step3} exact />
                <Route path={`${path}/success`} component={Step4} />
                <Route path={`${path}/error`} component={PinError} />
                <Route path={`${path}/3/:slug`} component={Step3} />
              </Switch>
            </Col>
          </Grid>
        </Container>
      </Section>
    </Main>
  );
};

export default DeclareGoods;
