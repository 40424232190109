import React from 'react';
import cx from '@utils/classNames';

const BankLinks = ({ className }) => {
  return (
    <div className={cx('BankLinks', className)}>
      <div className='BankLinks__container'>
        <label className='BankLinks__item'>
          <img src='/media/seb.png' alt='logo' />
          <input type='radio' name='select_banklink' />
          <span className='BankLinks__checkmark' />
        </label>
        <label className='BankLinks__item'>
          <img src='/media/sb.png' alt='logo' />
          <input type='radio' name='select_banklink' />
          <span className='BankLinks__checkmark' />
        </label>
        <label className='BankLinks__item'>
          <img src='/media/dnb.png' alt='logo' />
          <input type='radio' name='select_banklink' />
          <span className='BankLinks__checkmark' />
        </label>
        <label className='BankLinks__item'>
          <img src='/media/nordea.png' alt='logo' />
          <input type='radio' name='select_banklink' />
          <span className='BankLinks__checkmark' />
        </label>
        <label className='BankLinks__item'>
          <img src='/media/seb.png' alt='logo' />
          <input type='radio' name='select_banklink' />
          <span className='BankLinks__checkmark' />
        </label>
        <label className='BankLinks__item'>
          <img src='/media/sb.png' alt='logo' />
          <input type='radio' name='select_banklink' />
          <span className='BankLinks__checkmark' />
        </label>
      </div>
    </div>
  );
};

export default BankLinks;
