import React from 'react';
import { Grid, Input, Col } from '@ui';

const CardPayment = () => {
  return (
    <Grid hasGutter>
      <Col size='50%'>
        <Input className='mb-2' label={{ text: 'Vardas ir pavardė*' }} input={{ name: 'name_surname', type: 'text' }} />
      </Col>
      <Col size='50%'>
        <Input className='mb-2' label={{ text: 'Kortelės nr.*' }} input={{ name: 'card_no', type: 'number' }} />
      </Col>
      <Col size='50%'>
        <Input className='mb-2' label={{ text: 'CVC kodas*' }} input={{ name: 'cvc', type: 'number' }} />
      </Col>

      <Col size='50%'>
        <Input
          className='mb-2'
          label={{ text: 'Kortelės galiojimo data*' }}
          input={{ name: 'expired', type: 'text' }}
        />
      </Col>
    </Grid>
  );
};

export default CardPayment;
