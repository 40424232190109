import React from 'react';
import PropTypes from 'prop-types';
import cx from '@utils/classNames';

const TextBlock = ({ color, size, className, children, ...rest }) => {
  return (
    <div className={cx('TextBlock', `TextBlock--${size}`, `text-${color}`, className)} {...rest}>
      {children}
    </div>
  );
};

TextBlock.propTypes = {
  size: PropTypes.oneOf(['l', 'm']),
  color: PropTypes.oneOf(['black', 'white', 'grey-dark']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TextBlock.defaultProps = {
  color: 'grey-dark',
  size: 'm',
};

export default TextBlock;
