import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '@assets//icons/close.svg';

ReactModal.setAppElement('#modal');

const Modal = ({ children, onRequestClose, ...rest }) => {
  return (
    <ReactModal onRequestClose={onRequestClose} overlayClassName='Modal__overlay' className='Modal__content' {...rest}>
      <div className='Modal__container'>
        <button onClick={() => onRequestClose()} className='Modal__close' type='button' aria-label='close'>
          <CloseIcon />
        </button>
        {children}
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
};

export default Modal;
