import React from 'react';
import { Grid, Col, TextBlock, Link, Heading, Input, TextArea, Checkbox, Button } from '@ui';

const ContactForm = () => {
  return (
    <Grid className='ContactForm'>
      <Col className='ContactForm__left shadow-2 m:shadow-none bg-white'>
        <div className='ContactForm__image' style={{ backgroundImage: 'url(/media/contact@2x.png)' }}></div>
        <form className='ContactForm__form m:mb-9'>
          <Heading text='Palikite žinutę' className='mb-2 m:mb-3' size='xs' component='h2' />
          <Input className='mb-2' label={{ text: 'Vardas*' }} input={{ name: 'name', type: 'text' }} />
          <Input className='mb-2' label={{ text: 'El. paštas*' }} input={{ name: 'email', type: 'email' }} />
          <Input className='mb-2' label={{ text: 'Telefonas' }} input={{ name: 'phone', type: 'text' }} />
          <TextArea className='mb-5 m:mb-9' label={{ text: 'Žinutė*' }} textarea={{ name: 'message' }} />
          <Col className='mb-5 m:mb-6'>
            <Checkbox
              label={{
                text: (
                  <>
                    Sutinku su deklaruok24.lt &nbsp;
                    <Link to='/' text='privatumo politika' />.
                  </>
                ),
              }}
            />
          </Col>
          <Button text='siųsti' type='sbmit' />
        </form>
      </Col>
      <Col className='ContactForm__right'>
        <TextBlock className='font-light mb-9 m:mb-6  mt-7 '>
          <p>
            <strong>Pagalba deklaravimui</strong>
            <br />
            +370 699 12345
            <br />
            <Link href='mailto:info@deklaruok24.lt' text='info@deklaruok24.lt' />
          </p>
        </TextBlock>
        <TextBlock className='font-light mb-9 m:mb-0'>
          <p>
            <strong>Partneriams</strong>
            <br />
            +370 699 12345
            <br />
            <Link href='dainius@deklaruok24.lt' text='dainius@deklaruok24.lt' />
          </p>
        </TextBlock>
        <TextBlock className='font-light mb-9 m:mb-6 m:mt-14 m:pl-11'>
          <p>
            <strong>Ofiso darbo laikas</strong>
            <br />
            I-V: 9.00-19.00
          </p>
        </TextBlock>
        <TextBlock className='font-light mb-5 m:mb-0 m:pl-11'>
          <p>
            <strong>Ofiso adresas</strong>
            <br />
            Klaipėdos pr. 123,
            <br />
            LT-12345 Klaipėda
          </p>
        </TextBlock>
      </Col>
    </Grid>
  );
};

export default ContactForm;
