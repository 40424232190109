import React from 'react';
import { Section, Col, Container, Heading, TextBlock, Link, Main } from '@ui';
import useMobile from '@utils/useMobile';

const Price = () => {
  const { isMobile } = useMobile();
  return (
    <Main className='bg-sand-light m:bg-white'>
      <Section>
        <Container className='pt-13 m:pt-19 pb-20 m:pb-5'>
          <div className='mb-5 m:mb-12 m:-mx-6 m:py-6 m:px-6 m:bg-sand-light'>
            <Heading text='Kaina' className='m:hidden' size='s' component='h1' />
            <Heading text='Kaina' className='hidden m:block' size='m' component='h1' />
          </div>
          <TextBlock className='mb-6 m:mb-10'>
            <p>Kainą sudaro deklaruok24.lt paslaugos mokestis ir mokesčiai muitinei.</p>
          </TextBlock>
          <Heading text='Mokesčiai muitinei' className='mb-3 m:mb-1' size='xs' component='h2' />
          <Col className='bg-white px-9 m:px-0 py-5 m:py-0 mb-9 m:mb-13 shadow-2 m:shadow-none'>
            <div className='Table Table--regular'>
              <table>
                <thead>
                  <tr>
                    <td {...(!isMobile && { width: '190px' })}>
                      <h3>Mokesčio tipas </h3>
                    </td>
                    <td>
                      <h3>Mokesčio taikymo taisyklė</h3>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>PVM mokestis</p>
                    </td>
                    <td>
                      <p>
                        Valstybės nustatytas PVM dydis. Moka siuntėjas arba gavėjas. Nustatoma pagal siuntėjo pateiktas
                        pristatymo sąlygas.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Importo mokestis</p>
                    </td>
                    <td>
                      <p>
                        Siuntoms iki 150 EUR netaikomas importo mokestis. Nuo 150 EUR mokestis apskaičiuojamas pagal
                        muitinės TARIC kodų klasifikatorių nustatytas normas.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Akcizas</p>
                    </td>
                    <td>
                      <p>Valstybės nustatytas akcizo dydis prekių grupei.</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          <Heading text='Deklaruok24.lt paslaugos' className='mb-5 m:mb-5' size='xs' component='h2' />
          <TextBlock className='pb-5'>
            <p>
              Standartinė kaina už deklaraciją — 20 EUR+PVM.
              <br />
              Jei turite daugiau, nei 10 siuntų per mėnesį — <Link to='/price' text='susisiekite' />.
            </p>
          </TextBlock>
        </Container>
      </Section>
    </Main>
  );
};

export default Price;
