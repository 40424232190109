import React, { useState } from 'react';
import { Link } from '@ui';
import { ReactComponent as MenuIcon } from '@assets/icons/menu.svg';

const DeveloperNav = () => {
  const menuList = [
    { to: '/', text: '01_home' },
    { to: '/declare', text: '02_pin' },
    { to: '/declare/error', text: '02_pin_error' },
    { to: '/declare/1', text: '03_siuntos informacija_pilnas' },
    { to: '/modals', text: '04_neatitinka duomenys' },
    { to: '/modals', text: '05_neatitinka duomenys_info' },
    { to: '/declare/2', text: '06_mokesčiai' },
    { to: '/declare/3', text: '07_mokėjimo būdas' },
    { to: '/declare/3/edit', text: '09_mokėjimo būdas_keisti' },
    { to: '/declare/3', text: '10_apmokėjimas_kortelė_juridinis' },
    { to: '/declare/success', text: '11_pateikta' },
    { to: '/about', text: '12_apie mus' },
    { to: '/price', text: '13_kaina' },
    { to: '/contacts', text: '14_kontaktai' },
    { to: '/privacy-policy', text: '15_tekstinis' },
  ];

  const [active, setActive] = useState(false);

  return (
    <div className='DeveloperNav'>
      <button onClick={() => setActive(!active)}>
        <MenuIcon width='3rem' fill='#FFF' />
      </button>
      {active && (
        <ul>
          {menuList.map((item, index) => (
            <li key={index} className='pb-1'>
              <Link onClick={() => setActive(false)} key={index} {...item} color='white' />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DeveloperNav;
