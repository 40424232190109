import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Input, Button } from '@ui';

const Search = ({ className, label, tooltip }) => {
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    history.push('/declare');
  };
  return (
    <form onSubmit={handleSubmit} className={`Search ${className && className}`}>
      <Input label={label} type='search' size='l' tooltip={tooltip} />
      <Button className='Search__button' text='Deklaruoti' type='submit' />
    </form>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  label: PropTypes.shape({}),
  tooltip: PropTypes.node,
};

export default Search;
