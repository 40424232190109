import React from 'react';
import { useHistory } from 'react-router-dom';
import { Heading, TextBlock, Input, Col, Button, Link } from '@ui';
import { Message } from '@components';
import { ReactComponent as Step1Icon } from '@assets/icons/step-1-success.svg';

const Pin = () => {
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(`${history.location.pathname}/1`);
  };
  return (
    <form className='shadow-2 bg-white m:bg-transparent p-8 m:shadow-none m:px-0 m:pt-8 m:pb-0' onSubmit={handleSubmit}>
      <Heading
        text={
          <span className='inline-flex align-center'>
            <Step1Icon width='1.55em' className='mr-3 m:mr-5' />
            Siuntos duomenys rasti!
          </span>
        }
        className='mb-3 m:mb-7'
        size='s'
        component='h2'
      />
      <TextBlock className='mb-4 m:mb-11'>
        <ul>
          <li>
            <p>
              <strong>Siuntos nr.:</strong> 67ASFA5VEWV5
            </p>
          </li>
          <li>
            <p>
              <strong>Kurjeris:</strong> ITELLA/DPD/OMNIVA
            </p>
          </li>
          <li>
            <p>
              <strong>Pirkimo sąskaitos nr.:</strong> AAABBBCCC12
            </p>
          </li>
          <li>
            <p>
              <strong>Pirkimo data:</strong> 2020-11-11
            </p>
          </li>
        </ul>
      </TextBlock>
      <Message color='violet' className='mb-4 m:mb-9'>
        <p>Deklaracijos užsakymui pateikti reikia suvesti PIN kodą, gautą el. paštu arba SMS</p>
      </Message>
      <Col size='50%' className='pr-2 mb-4 m:pr-0 m:mb-5'>
        <Input label={{ text: 'PIN kodas' }} />
      </Col>
      <Col className='flex align-center m:flex-col'>
        <Button style={{ minWidth: '19.2rem' }} text='Deklaruoti' type='submit' />
        <TextBlock className='ml-5 m:ml-0 m:mt-8'>
          <p>
            Negavote PIN kodo? <Link text='Pakartotinai išsiųsti' />
          </p>
        </TextBlock>
      </Col>
    </form>
  );
};

export default Pin;
