import React from 'react';
import { useHistory } from 'react-router-dom';
import useMobile from '@utils/useMobile';
import { Heading, Col, Checkbox, Link, Button } from '@ui';

const Step2 = () => {
  const history = useHistory();
  const { isMobile } = useMobile();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(`/declare/3`);
  };
  return (
    <form className='shadow-2 m:shadow-none bg-white m:bg-transparent p-8 m:px-0 m:pt-8 m:pb-0' onSubmit={handleSubmit}>
      <Heading text='Mokesčiai' className='mb-3 m:mb-2' size='s' component='h2' />
      <div className='Table'>
        {!isMobile && (
          <table>
            <thead>
              <tr>
                <td>
                  <h3>Mokesčio tipas</h3>
                </td>
                <td width='80'>
                  <h3>Suma, €</h3>
                </td>
                <td>
                  <h3>Mokesčio taikymo taisyklė</h3>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>PVM mokestis</p>
                </td>
                <td>
                  <p>10</p>
                </td>
                <td>
                  <p>Pagal siuntėjo pateiktas pristatymo sąlygas (INCOTERM 10) PVM moka gavėjas</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Importo mokestis</p>
                </td>
                <td>
                  <p>0</p>
                </td>
                <td>
                  <p>Siuntoms iki 150 EUR netaikomas importo mokestis</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Akcizas</p>
                </td>
                <td>
                  <p>0</p>
                </td>
                <td>
                  <p>Prekės identifikuotos, kaip neakcizinės</p>
                </td>
              </tr>
              <tr>
                <td className='pb-2'>
                  <p>Deklaravimo mokestis</p>
                </td>
                <td>
                  <p>20</p>
                </td>
                <td>
                  <p>Muitinės tarpininko mokestis už smulkios siuntos deklaracijos administravimą</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Viso</p>
                </td>
                <td>
                  <p>30</p>
                </td>
                <td>
                  <p>Viso paskaičiuota mokesčių suma</p>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {isMobile && (
          <table className='w-full'>
            <thead>
              <tr>
                <td>
                  <h3>Tipas</h3>
                </td>
                <td width='84'>
                  <h3>Suma, €</h3>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='pb-3' style={{ paddingTop: '1.8rem' }}>
                  <p>
                    <strong>PVM mokestis</strong>
                  </p>
                  <p>
                    <small>Pagal siuntėjo pateiktas pristatymo sąlygas (INCOTERM 10)</small>
                  </p>
                </td>
                <td style={{ paddingTop: '1.8rem' }}>
                  <p>10</p>
                </td>
              </tr>
              <tr>
                <td className='pb-3'>
                  <p>
                    <strong>Importo mokestis</strong>
                  </p>
                  <p>
                    <small>Siuntoms iki 150 EUR netaikomas importo mokestis</small>
                  </p>
                </td>
                <td>
                  <p>0</p>
                </td>
              </tr>
              <tr>
                <td className='pb-3'>
                  <p>
                    <strong>Akcizas</strong>
                  </p>
                  <p>
                    <small>Prekės identifikuotos, kaip neakcizinės</small>
                  </p>
                </td>
                <td>
                  <p>0</p>
                </td>
              </tr>
              <tr>
                <td className='pb-3'>
                  <p>
                    <strong>Deklaravimo mokestis</strong>
                  </p>
                  <p>
                    <small>Muitinės tarpininko mokestis už smulkios siuntos deklaracijos administravimą</small>
                  </p>
                </td>
                <td>
                  <p>20</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Viso</p>
                </td>

                <td>
                  <p>30</p>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <Col className='mt-6'>
        <Checkbox
          label={{
            text: (
              <>
                Sutinku su &nbsp;
                <Link to='/' text='paslaugos sutartimi' hasUnderline /> &nbsp;ir&nbsp;{' '}
                <Link to='/' text='privatumo politika' hasUnderline />
              </>
            ),
          }}
        />
      </Col>
      <Col className='mt-6'>
        <Button text='Toliau: apmokėjimas' type='submit' />
      </Col>
    </form>
  );
};

export default Step2;
